import React, { useEffect, useRef, useState } from 'react';
import { Loader } from '@googlemaps/js-api-loader';
import { GpxWaypoints, Track } from "../../types/models";
import NeurunApi from "../../api/neurun";
import WaterIcon from "../../assets/img/water-marker.svg";
import StartIcon from "../../assets/img/start-marker.svg";
import FinishIcon from "../../assets/img/finish-icon.svg";
import StartFinishIcon from "../../assets/img/start-finish-icon.svg";

type GoogleMaps = typeof google;

interface LatLng {
  lat: number;
  lng: number;
}

interface CustomMapProps {
  startPoint: LatLng;
  endPoint: LatLng;
  path: LatLng[];
  waypoints: GpxWaypoints[];
  onUpdatePath: (updatedPath: LatLng[]) => void;
  saveGpx: boolean;
  gpxTracks: Track[];
  gpxGuid: string;
  setSaveGpx: (saveGpx: boolean) => void;
  raceUnit: string;
}

const CustomMap: React.FC<CustomMapProps> = ({
                                               startPoint,
                                               endPoint,
                                               path,
                                               waypoints,
                                               onUpdatePath,
                                               gpxTracks,
                                               saveGpx,
                                               gpxGuid,
                                               setSaveGpx,
                                               raceUnit
                                             }) => {
  const mapRef = useRef<HTMLDivElement | null>(null);
  const lineRef = useRef<google.maps.Polyline | null>(null);
  const [map, setMap] = useState<google.maps.Map | null>(null);
  const [isPlacingAidStation, setIsPlacingAidStation] = useState(false);
  const [updatedWaypoints, setUpdatedWaypoints] = useState<GpxWaypoints[]>([]);

  useEffect(() => {
    if (saveGpx) {
      updateGpx();
      setSaveGpx(false);
    }
  }, [saveGpx]);

  const updateGpx = async () => {
    const payload = {
      waypoints: updatedWaypoints,
      tracks: gpxTracks,
    };

    await NeurunApi.updateGpx(payload, gpxGuid);
  };

  useEffect(() => {
    if (map) {
      map.addListener('click', (event: google.maps.MapMouseEvent) => {
        handleMapClick(event, updatedWaypoints);
      });
    }
  }, [map, isPlacingAidStation]);

  useEffect(() => {
    if (map && lineRef.current) {
      placeKilometerMarkers(google, map, onUpdatePath, raceUnit);
    }
  }, [raceUnit]);

  useEffect(() => {
    const loader = new Loader({
      apiKey: 'YOUR_GOOGLE_MAPS_API_KEY',
      libraries: ['geometry'],
    });

    loader.load().then((google: GoogleMaps) => {
      const mapOptions: google.maps.MapOptions = {
        center: new google.maps.LatLng(startPoint.lat, startPoint.lng),
        zoom: 16,
        mapTypeId: google.maps.MapTypeId.ROADMAP,
      };
      const startWaypointIndex = updatedWaypoints.findIndex(i => i.properties.name === 'Start');

      const startPosition = {
        lat: startWaypointIndex !== -1 ? updatedWaypoints[startWaypointIndex]?.geometry.coordinates[0].lat : startPoint.lat,
        lng: startWaypointIndex !== -1 ? updatedWaypoints[startWaypointIndex]?.geometry.coordinates[0].lon : startPoint.lng,
      };

      const finishWaypointIndex = updatedWaypoints.findIndex(i => i.properties.name === 'Finish');

      const finishPosition = {
        lat: finishWaypointIndex !== -1 ? updatedWaypoints[finishWaypointIndex]?.geometry.coordinates[0].lat : path.slice(-1)[0].lat,
        lng: finishWaypointIndex !== -1 ? updatedWaypoints[finishWaypointIndex]?.geometry.coordinates[0].lon : path.slice(-1)[0].lng,
      };

      const isStartFinishInOnePlace = finishPosition.lat === startPosition.lat && finishPosition.lng && startPosition.lng;

      const mapInstance = new google.maps.Map(mapRef.current as HTMLDivElement, mapOptions);
      setMap(mapInstance);

      const lineCoordinates = path.map((point) => new google.maps.LatLng(point.lat, point.lng));

      const data = updatedWaypoints?.length ? updatedWaypoints : waypoints;
      const initialWaypoints = [...data];
      setUpdatedWaypoints(initialWaypoints);

      mapInstance.setCenter(new google.maps.LatLng(startPoint.lat, startPoint.lng));
      const line = new google.maps.Polyline({
        path: lineCoordinates,
        strokeColor: '#6271FF',
        strokeOpacity: 2.0,
        strokeWeight: 5,
        map: mapInstance,
      });

      lineRef.current = line;

      const aidStationIcon = {
        url: WaterIcon,
        scaledSize: new google.maps.Size(32, 32),
        origin: new google.maps.Point(0, 0),
        anchor: new google.maps.Point(16, 32),
      };

      const startIcon = {
        url: isStartFinishInOnePlace ? StartFinishIcon : StartIcon,
        scaledSize: new google.maps.Size(52, 52),
        origin: new google.maps.Point(0, 0),
        anchor: new google.maps.Point(16, 32),
      };

      const finishIcon = {
        url: FinishIcon,
        scaledSize: new google.maps.Size(52, 52),
        origin: new google.maps.Point(0, 0),
        anchor: new google.maps.Point(16, 32),
      };

      const startMarker = new google.maps.Marker({
        position: new google.maps.LatLng(startPosition.lat, startPosition.lng),
        map: mapInstance,
        icon: startIcon,
        draggable: true,
      });

      const startData: GpxWaypoints = {
        type: "Feature",
        geometry: {
          type: "Point",
          coordinates: [{
            lon: startPosition.lng,
            lat: startPosition.lat,
            ele: 0
          }]
        },
        properties: {
          desc: "Start Point",
          name: "Start",
          sym: "Waypoint",
          type: "Start"
        }
      };

      if (startWaypointIndex !== -1) {
        updatedWaypoints[startWaypointIndex] = startData;
        setUpdatedWaypoints(updatedWaypoints);
      } else {
        setUpdatedWaypoints((prev) => [...prev, startData]);
      }

      startMarker.addListener('dragend', (event: any) => {
        const newPosition = event.latLng;
        const closestPoint = getClosestPointOnRoute(line, newPosition);
        startMarker.setPosition(closestPoint);

        const updatedPath = [{ lat: closestPoint.lat(), lng: closestPoint.lng() }, ...path.slice(1)];
        onUpdatePath(updatedPath);

        const startData: GpxWaypoints = {
          type: "Feature",
          geometry: {
            type: "Point",
            coordinates: [{
              lon: closestPoint.lng(),
              lat: closestPoint.lat(),
              ele: 0
            }]
          },
          properties: {
            desc: "Start Point",
            name: "Start",
            sym: "Waypoint",
            type: "Start"
          }
        };

        const startWaypointIndex = updatedWaypoints.findIndex(i => i.properties.name === 'Start');

        if (startWaypointIndex !== -1) {
          updatedWaypoints[startWaypointIndex] = startData;
          setUpdatedWaypoints(updatedWaypoints);
        } else {
          setUpdatedWaypoints((prev) => [...prev, startData]);
        }
      });

      if (!isStartFinishInOnePlace) {
        const finishMarker = new google.maps.Marker({
          position: new google.maps.LatLng(finishPosition?.lat, finishPosition?.lng),
          map: mapInstance,
          icon: finishIcon,
          draggable: true,
        });

        const finishData: GpxWaypoints = {
          type: "Feature",
          geometry: {
            type: "Point",
            coordinates: [{
              lon: finishPosition?.lng,
              lat: finishPosition?.lat,
              ele: 0
            }]
          },
          properties: {
            desc: "Finish Point",
            name: "Finish",
            sym: "Waypoint",
            type: "Finish"
          }
        };

        if (finishWaypointIndex !== -1) {
          updatedWaypoints[finishWaypointIndex] = finishData;
          setUpdatedWaypoints(updatedWaypoints);
        } else {
          setUpdatedWaypoints((prev) => [...prev, finishData]);
        }

        finishMarker.addListener('dragend', (event: any) => {
          const newPosition = event.latLng;
          const closestPoint = getClosestPointOnRoute(line, newPosition);
          finishMarker.setPosition(closestPoint);

          const updatedPath = [...path.slice(0, -1), { lat: closestPoint.lat(), lng: closestPoint.lng() }];
          onUpdatePath(updatedPath);

          const finishData: GpxWaypoints = {
            type: "Feature",
            geometry: {
              type: "Point",
              coordinates: [{
                lon: closestPoint.lng(),
                lat: closestPoint.lat(),
                ele: 0
              }]
            },
            properties: {
              desc: "Finish Point",
              name: "Finish",
              sym: "Waypoint",
              type: "Finish"
            }
          };

          const finishWaypointIndex = updatedWaypoints.findIndex(i => i.properties.name === 'Finish');

          if (finishWaypointIndex !== -1) {
            updatedWaypoints[finishWaypointIndex] = finishData;
            setUpdatedWaypoints(updatedWaypoints);
          } else {
            setUpdatedWaypoints((prev) => [...prev, finishData]);
          }

        });
      }

      initialWaypoints.forEach((waypoint, index) => {
        if (waypoint.properties.type !== 'DistanceMarker' && waypoint.properties.type !== 'Start' && waypoint.properties.type !== 'Finish') {
          const coordinates = waypoint.geometry.coordinates[0];
          const marker = new google.maps.Marker({
            position: new google.maps.LatLng(coordinates.lat, coordinates.lon),
            map: mapInstance,
            icon: aidStationIcon,
            draggable: true,
          });

          google.maps.event.addListener(marker, 'dragend', (event: any) => {
            const newPosition = event.latLng;
            const closestPoint = getClosestPointOnRoute(line, newPosition);
            marker.setPosition(closestPoint);

            const updatedWaypoint = {
              ...waypoint,
              geometry: {
                ...waypoint.geometry,
                coordinates: [{
                  lon: closestPoint.lng(),
                  lat: closestPoint.lat(),
                  ele: coordinates.ele,
                }]
              }
            };

            const newUpdatedWaypoints = [...initialWaypoints];
            newUpdatedWaypoints[index] = updatedWaypoint;
            setUpdatedWaypoints(newUpdatedWaypoints);
          });
        }
      });

      placeKilometerMarkers(google, mapInstance, onUpdatePath, raceUnit);
    });
  }, [startPoint, endPoint, path, waypoints, onUpdatePath, raceUnit]);

  const createKmMarkerIcon = (km: number): google.maps.Icon => {
    const canvas = document.createElement('canvas');
    canvas.width = 40;
    canvas.height = 40;
    const context = canvas.getContext('2d');

    if (context) {
      // Draw circle
      context.beginPath();
      context.arc(20, 20, 18, 0, 2 * Math.PI, false);
      context.fillStyle = '#4C57BC';
      context.fill();
      context.lineWidth = 3;
      context.strokeStyle = '#FFFFFF';
      context.stroke();

      // Draw text
      context.fillStyle = '#FFFFFF';
      context.font = '16px Arial';
      context.textAlign = 'center';
      context.textBaseline = 'middle';
      context.fillText(km.toString(), 20, 20);
    }

    return {
      url: canvas.toDataURL(),
      scaledSize: new google.maps.Size(40, 40),
      origin: new google.maps.Point(0, 0),
      anchor: new google.maps.Point(20, 20),
    };
  };

  const placeKilometerMarkers = (
    google: GoogleMaps,
    map: google.maps.Map,
    onUpdatePath: (updatedPath: LatLng[]) => void,
    unit: string
  ) => {
    const distancePerKm = unit === 'metric' ? 1000 : 1609.34; // 1000 meters for metric, 1609.34 meters for imperial
    let kmCount = 1;

    const filteredWaypoints = updatedWaypoints.filter(
      (waypoint) => waypoint.properties.type !== "DistanceMarker"
    );

    setUpdatedWaypoints(filteredWaypoints);

    let distanceTraveled = 0;
    const sphericalLib = google.maps.geometry.spherical;

    for (let i = 1; i < path.length; i++) {
      const segmentDistance = sphericalLib.computeDistanceBetween(
        new google.maps.LatLng(path[i - 1].lat, path[i - 1].lng),
        new google.maps.LatLng(path[i].lat, path[i].lng)
      );
      distanceTraveled += segmentDistance;

      if (distanceTraveled >= distancePerKm) {
        const position = new google.maps.LatLng(path[i].lat, path[i].lng);

        const marker = new google.maps.Marker({
          position: position,
          map: map,
          icon: createKmMarkerIcon(kmCount),
          draggable: true,
          title: `KM ${kmCount}`,
        });

        const newKmWaypoint: GpxWaypoints = {
          type: "Feature",
          geometry: {
            type: "Point",
            coordinates: [
              {
                lon: position.lng(),
                lat: position.lat(),
                ele: 0
              }
            ]
          },
          properties: {
            desc: `Kilometer ${kmCount}`,
            name: `KM ${kmCount}`,
            sym: "Waypoint",
            type: "DistanceMarker"
          }
        };

        filteredWaypoints.push(newKmWaypoint);

        google.maps.event.addListener(marker, 'dragend', (event: any) => {
          const newPosition = event.latLng;
          const closestPoint = getClosestPointOnRoute(lineRef.current!, newPosition);
          marker.setPosition(closestPoint);

          const distanceMarkerIndex = filteredWaypoints.findIndex(
            (i) =>
              i.properties.name === `KM ${kmCount}`
          );
          const updatedWaypoint = filteredWaypoints[distanceMarkerIndex];
          const coordinates = updatedWaypoint?.geometry?.coordinates[0];

          const updatedData = {
            ...updatedWaypoint,
            geometry: {
              ...updatedWaypoint?.geometry,
              coordinates: [{
                lon: closestPoint.lng(),
                lat: closestPoint.lat(),
                ele: coordinates?.ele,
              }]
            }
          };

          filteredWaypoints[distanceMarkerIndex] = updatedData;
          setUpdatedWaypoints([...filteredWaypoints]);
        });

        kmCount++;
        distanceTraveled -= distancePerKm;
      }
    }

    setUpdatedWaypoints([...filteredWaypoints]);
  };


  const getClosestPointOnRoute = (
    line: google.maps.Polyline,
    point: google.maps.LatLng
  ): google.maps.LatLng => {
    const path = line.getPath().getArray();
    let closestPoint = path[0];
    let minDistance = google.maps.geometry.spherical.computeDistanceBetween(point, closestPoint);

    for (let i = 1; i < path.length; i++) {
      const segmentStart = path[i - 1];
      const segmentEnd = path[i];

      const closestSegmentPoint = getClosestPointOnSegment(segmentStart, segmentEnd, point);
      const distance = google.maps.geometry.spherical.computeDistanceBetween(point, closestSegmentPoint);

      if (distance < minDistance) {
        minDistance = distance;
        closestPoint = closestSegmentPoint;
      }
    }

    return closestPoint;
  };

  const getClosestPointOnSegment = (
    start: google.maps.LatLng,
    end: google.maps.LatLng,
    point: google.maps.LatLng
  ): google.maps.LatLng => {
    const latLngToVector = (latLng: google.maps.LatLng) => ({
      x: latLng.lng(),
      y: latLng.lat(),
    });

    const vectorToLatLng = (vector: { x: number; y: number }) => new google.maps.LatLng(vector.y, vector.x);

    const startVector = latLngToVector(start);
    const endVector = latLngToVector(end);
    const pointVector = latLngToVector(point);

    const segmentVector = { x: endVector.x - startVector.x, y: endVector.y - startVector.y };
    const pointStartVector = { x: pointVector.x - startVector.x, y: pointVector.y - startVector.y };

    const segmentLengthSquared = segmentVector.x * segmentVector.x + segmentVector.y * segmentVector.y;
    const t = Math.max(0, Math.min(1, (pointStartVector.x * segmentVector.x + pointStartVector.y * segmentVector.y) / segmentLengthSquared));

    return vectorToLatLng({
      x: startVector.x + t * segmentVector.x,
      y: startVector.y + t * segmentVector.y,
    });
  };

  const handleMapClick = (event: google.maps.MapMouseEvent, data: GpxWaypoints[]) => {
    if (isPlacingAidStation && event.latLng && lineRef.current) {
      const aidStationIcon = {
        url: WaterIcon,
        scaledSize: new google.maps.Size(32, 32),
        origin: new google.maps.Point(0, 0),
        anchor: new google.maps.Point(16, 32),
      };

      const closestPoint = getClosestPointOnRoute(lineRef.current, event.latLng);

      const marker = new google.maps.Marker({
        position: closestPoint,
        map: map!,
        icon: aidStationIcon,
      });

      const newAidStation: GpxWaypoints = {
        type: "Feature",
        geometry: {
          type: "Point",
          coordinates: [
            {
              lon: closestPoint.lng(),
              lat: closestPoint.lat(),
              ele: 0
            }
          ]
        },
        properties: {
          desc: "Custom Aid Station",
          name: "Aid Station",
          sym: "Waypoint",
          type: "AidStation"
        }
      };

      marker.addListener('click', () => {
        marker.setMap(null);
        setUpdatedWaypoints((prev) => prev.filter(waypoint => waypoint !== newAidStation));
      });

      setUpdatedWaypoints((prev) => {
        const newWaypoints = [...prev, newAidStation];
        return newWaypoints;
      });

      // setIsPlacingAidStation(false);
    }
  };

  const handleAidStationButtonClick = () => {
    setIsPlacingAidStation(!isPlacingAidStation);
  };

  return (
    <>
      <button type="button" onClick={handleAidStationButtonClick} style={styles.btn}>{isPlacingAidStation ? 'Please mark the Aid Station on the map' : 'Add Aid Station'}</button>
      <div ref={mapRef} style={{ height: '500px', width: '100%' }} />
    </>
  );
};

const styles = {
  btn: {
    padding: '14px',
    width: '100%',
    fontWeight: '500',
    fontSize: '17px',
    borderRadius: '12px',
    border: '1px solid #6271FF',
    background: '#FFF',
    color: '#6271FF',
    cursor: 'pointer',
    margin: '36px 0 8px 0'
  }
};

export default React.memo(
  CustomMap,
  (prevProps, nextProps) => {
    return (
      prevProps.raceUnit === nextProps.raceUnit && prevProps.saveGpx === nextProps.saveGpx
    )
  }
);
